<template>
  <div
    class="pa-4"
    v-if="isLoading"
  >
    <v-progress-linear
      indeterminate
      color="primary"
    />
  </div>
  <v-row
    v-else
    dense
  >
    <v-col
      cols="12"
      lg="11"
    >
      <v-text-field
        :label="label"
        v-model="itemValue"
        rounded
        dense
        outlined
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      lg="1"
    >
      <v-btn
        :class="{ primary: itemValue != '' }"
        :disabled="itemValue == ''"
        @click="addItem"
        rounded
        icon
      >
        <v-icon
          color="white"
          small
        >
          fas fa-plus
        </v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      lg="12"
    >
      <h4
        class="text-center grey--text pt-2"
        v-show="items.length === 0"
      >
        {{ noDataText }}
      </h4>

      <v-list-item
        v-for="(item, idx) in items"
        :key="idx"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="$emit('removeItem', item)"
            icon
          >
            <v-icon
              small
              color="error"
              >fas fa-trash</v-icon
            >
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    items: Array,
    label: String,
    isLoading: Boolean,
    noDataText: String,
  },
  data: () => ({
    itemValue: '',
  }),
  methods: {
    addItem() {
      this.$emit('addItem', this.itemValue)
      this.itemValue = ''
    },
  },
}
</script>